.subitem {
    .arrow {
        position: relative;
    }
    .arrow:after {
        content: "";
        position: absolute;
        left: 19px;
        bottom: -1px;
        height: 40px;
        width: 26px;
        border-left: 2px solid #666;
        border-bottom: 2px solid #666;
    }

    &:nth-child(2) .arrow:after {
        bottom: -1px;
        height: 26px;
    }
}

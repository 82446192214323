.multi-search-select-component {
  .option {
    display: flex;

    .label {
      flex: 1;
    }
  }

  .filter-display-line {
    display: flex;
    white-space: nowrap;
    width: 100%;
    padding-top: 3px;
  }

  &.size-small .MuiOutlinedInput-root {
    max-height: 40px;
  }

  &.size-medium .MuiOutlinedInput-root {
    max-height: 56px;
  }
}

@import "../../shared/constants.scss";

.transportplanlegger-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .full-calendar-wrapper {
    display: flex;
    flex-grow: 1;
    .calendar-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-right: 10px;
    }
    .has-assignments > .fc-datagrid-cell-frame {
      background-color: rgb(250, 249, 233);
    }
    .calendar-inner {
      flex: 1;

      .fc-datagrid-cell-cushion {
        width: 100%;
      }
      .MuiIconButton-root {
        padding: 0;
      }
    }

    .tasks-wrapper {
      width: 400px;
      height: calc(100vh - 64px);
      background-color: rgb(209, 209, 209);
      box-shadow: -0px 4px 10px 2px rgba(0, 0, 0, 0.4);
      z-index: 1100;

      #external-events {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .day-header {
        color: #474747;
        font-weight: bold;
        font-size: 20px;
        padding-left: 12px;
        padding-top: 5px;
        &:first-letter {
          text-transform: uppercase;
        }
      }

      .orders-content {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
      }

      .orders-header {
        padding: 10px;
        background-color: $secondary;
        min-height: 38px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-top: 2px solid rgb(209, 209, 209);
        border-bottom: 2px solid rgb(209, 209, 209);

        .title-wrapper {
          display: flex;

          .MuiButton-root {
            min-width: unset;
          }
        }

        .title {
          color: $primary;
          font-size: 18px;
          font-weight: bold;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 10px;
        }
        .filters {
        }
      }

      .task-item {
        padding: 1px;

        .MuiCardActionArea-root {
          padding: 5px 10px;
        }

        .line-wrapper {
          display: flex;
          justify-content: space-between;
        }

        .header {
          color: black;
          font-size: 14px;
          padding-bottom: 0;
        }

        .subtitle {
          padding-bottom: 5px;
          font-size: 15px;
        }

        .footer {
          display: flex;
          padding-bottom: 5px;
          font-size: 15px;

          .left {
            flex: 1;
            font-size: 12px;
            padding-top: 2px;
            svg {
              font-size: 12px;
              padding-right: 4px;
            }
          }

          .right {
            display: flex;
            .fraction {
              padding-right: 5px;
              display: flex;
              align-items: center;
            }
            svg {
              font-size: 16px;
              padding: 0;
              margin: 0;
              padding-left: 5px;
              margin-top: 2px;
            }
          }
        }
      }
      .task-item-undelivered {
        .MuiCard-root {
          background-color: rgba($NotDeliveredColor, 0.3);
        }
      }
    }

    .fc-toolbar-title {
      padding-left: 20px;
    }
  }

  .single-event-draggable {
    svg {
      transform: translateX(-10px);
    }
  }

  .orders-filter-partial {
    .MuiAutocomplete-tag {
      max-width: calc(100% - 75px);
    }
  }

  td[data-resource-id="show-more-equipment"] {
    border-color: rgba(0, 0, 0, 0);
  }
}
.underline {
  border-bottom: 1px solid gray;
}

.flexwrap {
  display: flex;
  flex-wrap: wrap;
}
// Intentionally outside so styles are
// applied while dragging

.single-event-draggable-parent {
  padding: 5px 0;
  .MuiCard-root {
    background-color: $primary;
    color: white;
  }
}

.single-event-draggable {
  display: flex;
  align-items: center;
  flex: 1;
  span {
    margin-left: -8px;
  }

  svg {
    transform: translateX(-5px);
  }
}

.buttons-to-left {
  margin-right: auto !important;
}

.align-status {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: inline-flex;
    gap: 18px;
  }
  .right {
    display: inline-flex;
    margin-right: 20px;
    gap: 18px;
  }
}

.contactPerson {
  position: absolute;
  left: 15px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-15 {
  font-size: 15px;
}
.font-size-17 {
  font-size: 17px;
}

.bold-text {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.text-align-end {
  text-align: end;
}

.inline-substitute {
  padding-left: 18px;
}

.linebreak {
  white-space: pre-line;
}

.shorten-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.right-aligned-vehicle {
  justify-content: space-between;
}

.vehicle-font {
  font-weight: 100;
}

.registration-font {
  font-weight: 900;
}

.updated-by {
  color: grey;
  font-size: 0.8em;
  flex: 1;
  display: flex;
  align-items: center;
}

.strike-text {
  text-decoration: line-through;
}

.fc-approveTodayButton-button.fc-approveTodayButton-button {
  background-color: $primary;
  border-color: $primary;
  &:hover {
    background-color: $primaryDark;
    border-color: $primaryDark;
  }
}

.loading .fc-approveTodayButton-button.fc-approveTodayButton-button {
  background-color: rgb(44, 62, 80);
  border-color: rgb(44, 62, 80);
  opacity: 0.65;
  cursor: wait;
}

.fc-timeline-event {
  min-height: 23px;
  cursor: pointer;
}

.fc-timeline-body {
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
}

.loading .fc-timeline-body {
  background-color: rgb(240, 240, 240);
}

.flex-gap-5 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.no-overflow {
  overflow: hidden;
  white-space: nowrap;
}
.fc-timeline-slot-frame .fc-timeline-slot-cushion {
  text-transform: capitalize;
}
.timeline-header-component {
  display: flex;
  padding: 10px 0px 10px 20px;
  gap: 10px;
  align-items: flex-start;

  .align-left {
    flex: 1;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .date-text {
    border: none;
    padding-left: 10px;
    width: 180px;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
    text-transform: capitalize;
    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }

  .formcontrol-flex-row {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .vehicle-filter-partial {
    width: 12%;
    padding-right: 40px;
  }

  .vehicle-form {
    width: 100%;

    .MuiOutlinedInput-root {
      max-height: 40px;
    }
  }

  .assignment-filter-partial {
    width: 40%;
    padding-right: 20px;
  }

  .width33 {
    width: 33%;

    .MuiOutlinedInput-root {
      max-height: 40px;
    }
  }

  .filter-display-line {
    display: flex;
    white-space: nowrap;
    // max-height: 40px;
    width: 100%;
    padding-top: 3px;
  }

  .filter-display-width {
    flex: 1;
  }

  .clear-background {
    background-color: red;
  }
}
.unavailability-title {
  min-width: 100px;
  color: #666666;
  font-size: 14px;
}
.unavailability-content {
  flex: 1;
  color: #111111;
  font-size: 16px;
}

.line-through {
  text-decoration: line-through;
}

.rental-modal-content-wrapper {
  min-height: 500px;
}

.fc-timeline-header-row:last-child {
  .slot-label {
    border-bottom: 1.5px solid #ddd;
  }
}

.fc .fc-scrollgrid-section {
  font-size: 15px;
}

.resource-style {
  .fc-datagrid-cell-cushion {
    margin: 0;
    padding: 4px;
    font-size: 14px;
  }
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.102);
  }
}

.dummy {
  background-color: rgb(246, 245, 245);
}

.left-header-partial {
  display: flex;
  align-items: center;
  padding: 0 10px;
  .header-text {
    flex: 1;
    text-align: left;
  }
}

.drawer-button {
  transform: translate(9.5px);
}

.fc-hide-lines {
  td.fc-timeline-slot {
    border-style: none;
  }

  td.fc-day-mon {
    border-style: solid;
  }
}

.expired-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fc-timeline-lane-frame,
.fc-datagrid-body .fc-datagrid-cell-frame {
  min-height: 34px;
}

.fc-timeline-event-harness:has(.white-arrow) {
  .fc-timeline-event:not(.fc-event-start):before,
  .fc-timeline-event:not(.fc-event-end):after {
    opacity: 0.9;
    border-right-color: white;
    border-left-color: white;
  }
}

.fc-timeline-event-harness:has(.project-expired) {
  border-color: $ExpProjectBorderColor;
  border-style: solid;
  border-width: 2px 1px 1px 2px;
  box-shadow: -1px -1px 0px 0px $ExpProjectBorderColor inset;
  -webkit-box-shadow: -1px -1px 0px 0px $ExpProjectBorderColor inset;
  -moz-box-shadow: -1px -1px 0px 0px $ExpProjectBorderColor inset;
  }
